<template>
  <div>
    <div class="data-control">
      <Search />
      
      <div class="btn-set">
        <router-link :to="'/property/'+slug+'/add'" class="btn btn-add">
          <i class="fa fa-plus"></i> Add <span class="mobile-hide">property</span>
        </router-link>
      </div>
    </div>

    <section v-if="getLoaded()" class="data-list">
      <table class="tb-data">
        <colgroup>
          <col class="col-order" />
          <col class="col-name" />
          <col class="col-refer" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>Order</th>
            <th>Name</th>
            <th>Refer ID</th>
            <th>Remark</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, i) in property.result" :key="i">
            <td>{{ item.priority }}</td>
            <td>
              <div class="item-detail">
                <router-link :to="'/property/'+slug+'/edit/' + item.propertyID" class="item-title">{{ item.name }}</router-link>
              </div>
              <!-- <ul class="item-action">
                <li><router-link :to="'user/edit/' + item.propertyID" class="edit">Edit</router-link></li>
                <li><a href="#" @click.prevent="deleteItem(item.propertyID)" class="delete" >Remove</a></li>
              </ul> -->
            </td>
            <td>{{ item.refer }}</td>
            <td>{{ item.remark }}</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <th>Order</th>
            <th>Name</th>
            <th>Refer ID</th>
            <th>Remark</th>
          </tr>
        </tfoot>
      </table>
    </section>

    <div class="data-control control-foot">
      <!-- <Pagination slug='user' :limit=queryParams.limit :total=property.total /> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';
import {property} from '@/variables/const.js';
import {cloneObject} from '@/utils/objects.js'

import Search from '@/components/common/Search.vue'
// import Pagination from '@/components/common/Pagination.vue'

export default {
  data() {
    return {
      loaded: false,
      slug: '',
      refer_props: {},
      property: {},

      queryParams:{
        limit: 30, 
        page: 1
      }
    }
  },
  components: {
    Search,
    // Pagination
  },
  watch:{
    $route() {
      this.loadPage();
    }
  },
  created() {
    this.slug = this.$route.params.slug;
    this.refer_props = property[this.slug];

    this.$store.commit('setPageTitle', ' Property : '+this.refer_props.name);
    this.$store.commit('setHeadTitle', this.refer_props.name+' | Property ');
    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    loadPage() {
      this.$store.commit('setLoaderShow', true);

      if(this.$route.query.page){
        this.queryParams.page = this.$route.query.page;
      }
      if(this.$route.query.q){
        this.queryParams.q = this.$route.query.q;
      }
      let queryParams = cloneObject(this.queryParams);
      // console.log(queryParams);

      apiRequest
        .get('/property/'+this.slug, { params: queryParams })
        .then( (res) => {
          this.property = res.data;
          console.log(this.property);
          this.$store.commit('setLoaderShow', false);
        })
    },
    deleteItem() { //id
      // let conf = confirm("Do you want to delete this item?");
      // if(conf) {
      //   // console.log('Delete item #'+id);
      //   this.$store.commit('setLoaderShow', true);

      //   let data  = {
      //     by:   this.$store.state.user.UID,
      //     auth: this.$store.state.user_session,
      //     id:   id,
      //   };
        
      //   axios
      //     .post(apiUrl+'user/delete', qs.stringify({data}, { parseArrays: false}) , {api_key: apiKey})
      //     .then( () => {
      //       this.loadPage();
      //     });
      // }
    }
  }
}
</script>


<style scoped>
.tb-data .col-order  {width: 80px;}
.tb-data .col-name  {width: 25%;}
.tb-data .col-refer {width: 100px;}
</style>